<template>
	<div id="activitylist">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>办公</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">活动申请</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="content u-f">
				<div class="content-left">
					<div class="u-f-item meun-item" :class="meunLeftIndex == 1 ? 'meun-active' : ''" @click="meunLeftTap(1)">
						<div class="iconfont icon-fabu"></div>
						<div>我的申请</div>
					</div>
					<div class="u-f-item meun-item" :class="meunLeftIndex == 2 ? 'meun-active' : ''" @click="meunLeftTap(2)">
						<div class="iconfont icon-caogao"></div>
						<div>抄送给我</div>
					</div>
					<div class="font_blod font_16 p-t-b">审核</div>
					<div class="u-f-item meun-item" :class="meunLeftIndex == 3 ? 'meun-active' : ''" @click="meunLeftTap(3)">
						<div class="iconfont icon-yishenhe"></div>
						<div>未审核</div>
					</div>
					<div class="u-f-item meun-item" :class="meunLeftIndex == 4 ? 'meun-active' : ''" @click="meunLeftTap(4)">
						<div class="iconfont icon-yishenhe1"></div>
						<div>已审核</div>
					</div>
				</div>
				<!-- 我的申请 -->
				<div class="content-right" v-if="meunLeftIndex == 1">
					<div class="title u-f-item u-f-jsb">
						<div>
							共{{ total }}条数据
							<!-- <span class="btn" @click="selectAll">选择全部</span> -->
						</div>
					</div>
					<el-table
						:data="Mylist"
						height="98%"
						ref="multipleTable"
						tooltip-effect="dark"
						@selection-change="handleSelectionChange"
						style="width: 100%;border: 1px solid #EEEEEE;"
						key="2"
					>
						<el-table-column type="selection" width="55"></el-table-column>
						<el-table-column label="序号" type="index" width="80" align="center"></el-table-column>
						<el-table-column prop="title" label="名称" align="center" :filters="filters" :filter-method="filterHandler"></el-table-column>
						<el-table-column prop="createtime" label="发起时间" align="center"></el-table-column>
						<el-table-column prop="status" label="审核状态" align="center">
							<template slot-scope="scope">
								<el-tag v-if="scope.row.status == 1">待审核</el-tag>
								<el-tag v-if="scope.row.status == 2">已通过</el-tag>
								<el-tag v-if="scope.row.status == 3">未通过</el-tag>
								<el-tag v-if="scope.row.status == 4">已撤回</el-tag>
							</template>
						</el-table-column>
						<el-table-column align="center" label="操作" width="150">
							<template slot-scope="scope">
								<el-button @click="openDetails(scope.row)" type="text" size="small">详情</el-button>
								<!-- <el-button @click="delcourse(scope.row.id, scope.row.time_id)" type="text" size="small" style="color:red;">撤回</el-button> -->
							</template>
						</el-table-column>
					</el-table>
					<div class="u-f-item u-f-jsb" style="padding: 15px 0;">
						<div class="u-f-item"><el-button type="danger" plain size="mini" @click="delActivit">删除</el-button></div>
						<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page.sync="page"
							:page-size="limit"
							layout="total, prev, pager, next"
							:total="total"
						></el-pagination>
					</div>
				</div>
				<!-- 我的抄送 -->
				<div class="content-right" v-if="meunLeftIndex == 2">
					<div class="title u-f-item u-f-jsb">
						<div>共{{ total }}条数据</div>
					</div>
					<el-table :data="copyToList" height="90%" style="width: 100%;border: 1px solid #EEEEEE;">
						<el-table-column label="序号" type="index" width="80" align="center"></el-table-column>
						<el-table-column prop="title" label="名称" align="center"></el-table-column>
						<el-table-column prop="createtime" label="发布时间" align="center"></el-table-column>
						<el-table-column prop="status" label="审核状态" align="center">
							<template slot-scope="scope">
								<el-tag v-if="scope.row.status == 1">待审核</el-tag>
								<el-tag v-if="scope.row.status == 2">已通过</el-tag>
								<el-tag v-if="scope.row.status == 3">未通过</el-tag>
								<el-tag v-if="scope.row.status == 4">已撤回</el-tag>
							</template>
						</el-table-column>
						<el-table-column align="center" label="操作" width="100">
							<template slot-scope="scope">
								<el-button @click="openDetails(scope.row)" type="text" size="small">详情</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div class="u-f-right" style="padding: 15px 0;">
						<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page.sync="page"
							:page-size="limit"
							layout="total, prev, pager, next"
							:total="total"
						></el-pagination>
					</div>
				</div>
				<!-- 未审核 -->
				<div class="content-right" v-if="meunLeftIndex == 3">
					<div class="title u-f-item u-f-jsb">
						<div>共{{ total }}条数据</div>
					</div>
					<el-table :data="copyToList" height="90%" style="width: 100%;border: 1px solid #EEEEEE;">
						<el-table-column label="序号" type="index" width="80" align="center"></el-table-column>
						<el-table-column prop="title" label="名称" align="center"></el-table-column>
						<el-table-column prop="createtime" label="发布时间" align="center"></el-table-column>
						<el-table-column prop="status" label="审核状态" align="center">
							<template slot-scope="scope">
								<el-tag v-if="scope.row.status == 1">待审核</el-tag>
								<el-tag v-if="scope.row.status == 2">已通过</el-tag>
								<el-tag v-if="scope.row.status == 3">未通过</el-tag>
								<el-tag v-if="scope.row.status == 4">已撤回</el-tag>
							</template>
						</el-table-column>
						<el-table-column align="center" label="操作" width="100">
							<template slot-scope="scope">
								<el-button @click="openDetails(scope.row)" type="text" size="small">详情</el-button>
								<el-button @click="openaudit(scope.row)" type="text" size="small" style="color:red;">审核</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div class="u-f-right" style="padding: 15px 0;">
						<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page.sync="page"
							:page-size="limit"
							layout="total, prev, pager, next"
							:total="total"
						></el-pagination>
					</div>
				</div>
				<!-- 已审核 -->
				<div class="content-right" v-if="meunLeftIndex == 4">
					<div class="title u-f-item u-f-jsb">
						<div>共{{ total }}条数据</div>
					</div>
					<el-table :data="copyToList" height="90%" style="width: 100%;border: 1px solid #EEEEEE;">
						<el-table-column label="序号" type="index" width="80" align="center"></el-table-column>
						<el-table-column prop="title" label="名称" align="center"></el-table-column>
						<el-table-column prop="createtime" label="发布时间" align="center"></el-table-column>
						<el-table-column prop="status" label="审核状态" align="center">
							<template slot-scope="scope">
								<el-tag v-if="scope.row.status == 1">待审核</el-tag>
								<el-tag v-if="scope.row.status == 2">已通过</el-tag>
								<el-tag v-if="scope.row.status == 3">未通过</el-tag>
								<el-tag v-if="scope.row.status == 4">已撤回</el-tag>
							</template>
						</el-table-column>
						<el-table-column align="center" label="操作" width="100">
							<template slot-scope="scope">
								<el-button v-if="scope.row.status == 2&&detailsType!=3&&detailsType!=6&&detailsType!=7" @click="print(scope.row)" type="text" size="small">打印</el-button>
								<el-button @click="openDetails(scope.row)" type="text" size="small">详情</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div class="u-f-right" style="padding: 15px 0;">
						<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page.sync="page"
							:page-size="limit"
							layout="total, prev, pager, next"
							:total="total"
						></el-pagination>
					</div>
				</div>
			</div>
			
			<!-- 详情 -->
			<el-dialog :title="detailsTitle" :visible.sync="activityVisible7" width="650px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">申请人：</div>
						<div>{{ info.petitioner_name }}</div>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">请假时长：</div>
						<div>{{ info.day }}天</div>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">开始时间：</div>
						<div>{{ info.start_time_text }}</div>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">结束时间：</div>
						<div>{{ info.end_time_text }}</div>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">请假类型：</div>
						<div>{{ info.type_text }}</div>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal">请假说明：</div>
						<div>{{ info.explain }}</div>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal">审核状态：</div>
						<div>{{ info.status_text }}</div>
					</div>
					<div class="u-f-item input-box" v-if="info.audit_opinions">
						<div class="input-lebal">审核备注：</div>
						<div>{{ info.audit_opinions }}</div>
					</div>
				</div>
				<div style="min-height: 200px;padding-left: 60px;margin-top: 40px;">
					<el-steps direction="vertical">
						<el-step title="审核人" v-if="info.audit&&info.audit.length">
							<div slot="description" style="padding-bottom: 10px;">
								<div v-for="(item,index) in info.audit" :key="index" style="margin-top: 10px;">
									<div class="u-f-item u-f-jsb">
										<div>{{item.audit_username}}</div>
										<div v-if="info.status!=4">
											<span v-if="item.handle==0">待上级审批</span>
											<span v-if="item.handle==1">待审批</span>
											<span v-if="item.handle==2">已审批</span>
											<span v-if="item.audit_time">{{item.audit_time|getLocalTime}}</span>
										</div>
										<div v-if="info.status==4">
											<span>已撤回</span>
										</div>
									</div>
									<div>{{item.audit_opinions}}</div>
								</div>
							</div>
						</el-step>
						<el-step title="承办人" v-if="info.exec&&info.exec.length">
							<div slot="description" style="padding-bottom: 10px;">
								<div v-for="(item,index) in info.exec" :key="index" style="margin-top: 10px;">
									<div class="u-f-item u-f-jsb">
										<div>{{item.audit_username}}</div>
										<div v-if="info.status!=4">
											<span v-if="item.handle==0">待上级审批</span>
											<span v-if="item.handle==1">待审批</span>
											<span v-if="item.handle==2">已审批</span>
											<span v-if="item.audit_time">{{item.audit_time|getLocalTime}}</span>
										</div>
										<div v-if="info.status==4">
											<span>已撤回</span>
										</div>
									</div>
									<div>{{item.audit_opinions}}</div>
								</div>
							</div>
						</el-step>
						<el-step title="抄送人" v-if="info.ectype&&info.ectype.length">
							<div slot="description">
								<div v-for="(item,index) in info.ectype" :key="index" style="margin-top: 10px;">
									<div class="u-f-item u-f-jsb">
										<div>{{item.audit_username}}</div>
										<div>
											<span v-if="info.exec_status==2">已抄送</span>
											<span v-if="info.status==4">已撤回</span>
											<span v-if="info.updatetime&&info.exec_status==2">{{info.updatetime|getLocalTime}}</span>
										</div>
									</div>
									<div>{{item.audit_opinions}}</div>
								</div>
							</div>
						</el-step>
					</el-steps>
				</div>
			</el-dialog>
			<!-- 审核 -->
			<div id="audit">
				<el-dialog title="审核" :visible.sync="activityVisible8" width="500px" :before-close="handleClose">
					<div class="edit-box">
						<div class="u-f input-box">
							<div class="input-lebal">是否通过</div>
							<el-radio-group v-model="audittype" style="width: 80%">
								<el-radio :label="1" style="margin-bottom: 10px;">通过</el-radio>
								<el-radio :label="2" style="margin-bottom: 10px;">不通过</el-radio>
							</el-radio-group>
						</div>
						<div class="u-f input-box">
							<div class="input-lebal">备注</div>
							<el-input v-model="remark" type="textarea" :rows="5" placeholder="请输入备注" style="width: 70%;"></el-input>
						</div>
					</div>
					<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
						<el-button @click="closeActivity" size="small">取 消</el-button>
						<el-button type="primary" size="small" @click="submitaudit()">确 定</el-button>
					</span>
				</el-dialog>
			</div>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			nums: [],
			selectShow: false,
			selectList: [],
			nodeIndex: -1,
			attendIndex: -1,
			// , { name: '流程报表' }, { name: '流程配置' }
			Meun: [{ name: '流程审批' }],
			MeunIndex: 0,
			meunLeftIndex: 1,
			total: 0,
			// 活动申请
			activityVisible: false,
			title: '',
			start_time: '',
			end_time: '',
			explain: '',
			main_department_id: '',
			ectype_department_id: '',
			audit: [],
			ectype: [],
			sectionList: [],
			peopleList: [],
			peopleList2: [],
			page: 1,
			limit: 10,
			Mylist: [],
			ids: [],
			approverList: [],
			copyToList: [],
			// 请假申请
			activityVisible1: false,
			start_time1: '',
			end_time1: '',
			day: '',
			type: 1,
			explain: '',
			// 安全隐患
			activityVisible2: false,
			site: '',
			describe: '',
			fileList: [],
			file: '',
			//公开课申请
			activityVisible3: false,
			plan: '',
			summarize: '',
			give_img: '', //授课照片
			success_img: '', //成功照片
			media_img: '', //功能室登记扫描件
			attendance_img: '', //考勤表扫描件
			activity_img: '', //活动记录表扫描件
			fileList2: [],
			fileList1: [],
			file3: '',
			file1: '',
			file2: '',
			// 物品领用
			activityVisible4: false,
			assettype: 2,
			number: '',
			property_warehouse_id: '',
			purpose: '',
			plantime: '',
			assetList: [],
			// 公文流转
			activityVisible5: false,
			fileTitle: '',
			level: 1,
			origin_units: '',
			origin_time: '',
			describe: '',
			opinion: '',
			file4: '',
			fileUrl: '',
			consume_id: [],
			consumeList: [],
			// 调课申请
			activityVisible6: false,
			week: '',
			weekList: [],
			CourseList: [],
			attend_day: [],
			value2: '',
			teacherList: [],
			teacherid: '',
			// 筛选
			info: '',
			activityVisible7: false,
			activityVisible8: false,
			audittype: 1,
			auditId: '',
			detailsTitle: '',
			detailsType: '',
			remark: '',
			filters: [
				{ text: '活动审批', value: '活动审批' },
				{ text: '档案审核', value: '档案审核' },
				{ text: '请假调休', value: '请假调休' },
				{ text: '课程调课', value: '课程调课' },
				{ text: '报修通知', value: '报修通知' },
				{ text: '公开课', value: '公开课' },
				{ text: '安全隐患上报', value: '安全隐患上报' },
				{ text: '物品领用', value: '物品领用' },
				{ text: '物品归还', value: '物品归还' },
				{ text: '学生请假', value: '学生请假' }
			],
			teacherIndex: -1,
			cocourse_id: '',
			teacher_list: [],
			typeIndex: 0,
			dataIndex: 0,
			keys:'',
			selectType:''
		};
	},
	filters:{
		getLocalTime(nS) {  
			return new Date(parseInt(nS) * 1000).toLocaleString().replace(/:\d{1,2}$/,' ');  
		}
	},
	mounted() {
		this.getAssetList();
		this.myActivitList();
		this.getweekList();
		this.getteacherList();
	},
	methods: {
		print(e) {
		    let {href}= this.$router.resolve({
				path: "/print",
				query: {
				    id: e.id,
					type:e.table_type
				}
			});
			window.open(href, '_blank');
		},
		selectShowTap(type){
			this.selectType = type;
			this.selectShow = true;
		},
		// 切换分类
		typeTap(index) {
			this.typeIndex = index;
			this.dataIndex = 0;
		},
		dataTap(index) {
			this.dataIndex = index;
		},
		// 教师列表
		getteacherList() {
			this.$api.pepople.teacherList({}).then(res => {
				if (res.data.code == 1) {
					this.teacher_list = res.data.data;
					for (let i in this.teacher_list) {
						for (let j in this.teacher_list[i].data) {
							for (let t in this.teacher_list[i].data[j].teacher) {
								this.nums = this.nums.concat(this.teacher_list[i].data[j].teacher[t].id);
								this.selectList = this.selectList.concat(this.teacher_list[i].data[j].teacher[t]);
							}
						}
					}
					this.nums = this.unique(this.nums);
					this.selectList = this.unique2(this.selectList);
				}
			});
		},
		unique(arr) {
			// 根据唯一标识orderId来对数组进行过滤
			const res = new Map(); //定义常量 res,值为一个Map对象实例 //返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
			return arr.filter(arr => !res.has(arr) && res.set(arr, 1));
		},
		unique2(arr) {
			// 根据唯一标识orderId来对数组进行过滤
			const res = new Map(); //定义常量 res,值为一个Map对象实例 //返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
			return arr.filter(arr => !res.has(arr.teacher_id) && res.set(arr.teacher_id, 1));
		},
		// 选择老师
		teachTap(item) {
			let arr = [];
			for (let i in this.teacher_list) {
				for (let j in this.teacher_list[i].data) {
					for (let t in this.teacher_list[i].data[j].teacher) {
						if (this.teacher_list[i].data[j].teacher[t].id == item.id && !this.teacher_list[i].data[j].teacher[t].checked) {
							this.teacher_list[i].data[j].teacher[t].checked = true;
						} else if (this.teacher_list[i].data[j].teacher[t].id == item.id && this.teacher_list[i].data[j].teacher[t].checked) {
							this.teacher_list[i].data[j].teacher[t].checked = false;
						}
						if (this.teacher_list[i].data[j].teacher[t].checked) {
							arr.push(this.teacher_list[i].data[j].teacher[t].id);
						}
					}
				}
			}
			if(this.selectType==1){
				this.audit = arr;
				this.audit = this.unique(this.audit);
			}else if(this.selectType==2){
				this.ectype = arr;
				this.ectype = this.unique(this.audit);
			}
			
		},
		removeTag() {
			for (let i in this.teacher_list) {
				for (let j in this.teacher_list[i].data) {
					for (let t in this.teacher_list[i].data[j].teacher) {
						if(this.selectType==1){
							if (this.audit.length) {
								for (let c in this.audit) {
									if (this.teacher_list[i].data[j].teacher[t].id == this.audit[c]) {
										this.teacher_list[i].data[j].teacher[t].checked = true;
									} else {
										this.teacher_list[i].data[j].teacher[t].checked = false;
									}
								}
							} else {
								this.teacher_list[i].data[j].teacher[t].checked = false;
							}
						}else if(this.selectType==2){
							if (this.ectype.length) {
								for (let c in this.ectype) {
									if (this.teacher_list[i].data[j].teacher[t].id == this.ectype[c]) {
										this.teacher_list[i].data[j].teacher[t].checked = true;
									} else {
										this.teacher_list[i].data[j].teacher[t].checked = false;
									}
								}
							} else {
								this.teacher_list[i].data[j].teacher[t].checked = false;
							}
						}
						
					}
				}
			}
		},
		teacherTap(index) {
			this.teacherIndex = index;
			for (let i in this.CourseList[this.nodeIndex].list) {
				if (this.CourseList[this.nodeIndex].list[i].id == this.cocourse_id) {
					this.CourseList[this.nodeIndex].list[i].teachername = this.teacherList[index].teacher_name;
					this.CourseList[this.nodeIndex].list[i].teacher_id = this.teacherList[index].teacher_id;
				}
			}
		},
		letin(index, id, i) {
			this.nodeIndex = index;
			this.attendIndex = i;
			this.cocourse_id = id;
			// this.getTeacherList(id)
		},
		// 获取学周列表
		getweekList() {
			this.$api.setting
				.getweekList({
					semester_id: window.localStorage.getItem('semester_id')
				})
				.then(res => {
					if (res.data.code == 1) {
						let data = res.data.data.list;
						let arr = [];
						for (let i = 0; i < data.length; i++) {
							arr.push({
								name: data[i],
								id: i + 1
							});
						}
						this.weekList = arr;
					}
				});
		},
		getTeacherList(id) {
			this.$api.rule
				.getTeacherList({
					course_config_id: id,
					week: this.week
				})
				.then(res => {
					if (res.data.code == 1) {
						this.teacherList = res.data.data.teacher_list;
						this.teacherIndex = -1;
					}
				});
		},
		// 学生请假审核
		studentAudit() {
			this.$api.rule
				.studentAudit({
					id: this.auditId,
					status: this.audittype,
					audit_opinions: this.remark
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('审核成功');
						this.getduplicate(3);
						this.activityVisible8 = false;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		// 活动审核
		activitiesAudit() {
			this.$api.rule
				.activitiesAudit({
					id: this.auditId,
					status: this.audittype,
					audit_opinions: this.remark
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('审核成功');
						this.getduplicate(3);
						this.activityVisible8 = false;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		// 请假审核
		leaveAudit() {
			this.$api.rule
				.leaveAudit({
					id: this.auditId,
					status: this.audittype,
					audit_opinions: this.remark
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('审核成功');
						this.getduplicate(3);
						this.activityVisible8 = false;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		// 校园报修审核
		repairAudit() {
			this.$api.rule
				.repairAudit({
					id: this.auditId,
					status: this.audittype,
					audit_opinions: this.remark
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('审核成功');
						this.getduplicate(3);
						this.activityVisible8 = false;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		// 公开课审核
		openClassAudit() {
			this.$api.rule
				.openClassAudit({
					id: this.auditId,
					status: this.audittype,
					audit_opinions: this.remark
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('审核成功');
						this.getduplicate(3);
						this.activityVisible8 = false;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		// 安全隐患审核
		potentialAudit() {
			this.$api.rule
				.potentialAudit({
					id: this.auditId,
					status: this.audittype,
					audit_opinions: this.remark
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('审核成功');
						this.getduplicate(3);
						this.activityVisible8 = false;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		// 资产管理审核
		propertyBorrowAudit() {
			this.$api.rule
				.propertyBorrowAudit({
					id: this.auditId,
					status: this.audittype,
					audit_opinions: this.remark
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('审核成功');
						this.getduplicate(3);
						this.activityVisible8 = false;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		submitaudit() {
			if (this.detailsType == 1) {
				this.activitiesAudit();
			} else if (this.detailsType == 2) {
			} else if (this.detailsType == 3) {
				this.leaveAudit();
			} else if (this.detailsType == 4) {
			} else if (this.detailsType == 5) {
				this.repairAudit();
			} else if (this.detailsType == 6) {
				this.openClassAudit();
			} else if (this.detailsType == 7) {
				this.potentialAudit();
			} else if (this.detailsType == 8) {
				this.propertyBorrowAudit();
			} else if (this.detailsType == 9) {
				this.propertyBorrowAudit();
			} else if (this.detailsType == 10) {
				this.studentAudit();
			}
		},
		// 审核
		openaudit(e) {
			this.audittype = 1;
			this.remark = '';
			this.detailsType = e.table_type;
			this.auditId = e.id;
			this.activityVisible8 = true;
		},
		// 详情
		openDetails(e) {
			this.detailsTitle = e.title;
			this.detailsType = e.table_type;
			if (e.table_type == 1) {
				this.activitDetails(e.id);
			} else if (e.table_type == 2) {
				this.leaveDetails(e.id);
			} else if (e.table_type == 3) {
			} else if (e.table_type == 4) {
			} else if (e.table_type == 5) {
				this.repairDetails(e.id);
			} else if (e.table_type == 6) {
				this.openClassDetails(e.id);
			} else if (e.table_type == 7) {
				this.potentialDetails(e.id);
			} else if (e.table_type == 8) {
				this.propertyBorrowDetails(e.id);
			} else if (e.table_type == 9) {
				this.propertyBorrowDetails(e.id);
			} else if (e.table_type == 10) {
				this.studentDetails(e.id);
			}else if(e.table_type == 11){
				this.getDocument(e.id)
			}
			this.activityVisible7 = true;
		},
		filterHandler(value, row, column) {
			const property = column['property'];
			return row[property] === value;
		},
		radioChange() {
			this.getAssetList();
		},
		weekChanage(e) {
			this.getCourse(e);
		},
		getFile(e) {
			if (this.$refs.filElem.files.length) {
				this.file2 = this.$refs.filElem.files[0];
				let myfile = this.$refs.filElem;
				let files = myfile.files;
				let file = files[0];
				var formData = new FormData();
				formData.append('file', file);
				this.$api.setting.uploadFile(formData).then(res => {
					if (res.data.code == 1) {
						this.media_img = res.data.data.url;
					} else {
						this.$message.error(res.data.msg);
					}
				});
			}
		},
		delFile() {
			this.file2 = '';
			this.media_img = '';
			this.$refs.filElem.value = '';
		},
		getFile1(e) {
			if (this.$refs.filElem1.files.length) {
				this.file1 = this.$refs.filElem1.files[0];
				let myfile = this.$refs.filElem1;
				let files = myfile.files;
				let file = files[0];
				var formData = new FormData();
				formData.append('file', file);
				this.$api.setting.uploadFile(formData).then(res => {
					if (res.data.code == 1) {
						this.attendance_img = res.data.data.url;
					} else {
						this.$message.error(res.data.msg);
					}
				});
			}
		},
		delFile1() {
			this.file1 = '';
			this.attendance_img = '';
			this.$refs.filElem1.value = '';
		},
		getFile2(e) {
			if (this.$refs.filElem2.files.length) {
				this.file2 = this.$refs.filElem2.files[0];
				let myfile = this.$refs.filElem2;
				let files = myfile.files;
				let file = files[0];
				var formData = new FormData();
				formData.append('file', file);
				this.$api.setting.uploadFile(formData).then(res => {
					if (res.data.code == 1) {
						this.activity_img = res.data.data.url;
					} else {
						this.$message.error(res.data.msg);
					}
				});
			}
		},
		delFile2() {
			this.file2 = '';
			this.$refs.filElem2.value = '';
			this.activity_img = '';
		},
		getFile4(e) {
			if (this.$refs.filElem4.files.length) {
				this.file4 = this.$refs.filElem4.files[0];
				let myfile = this.$refs.filElem4;
				let files = myfile.files;
				let file = files[0];
				var formData = new FormData();
				formData.append('file', file);
				this.$api.setting.uploadFile(formData).then(res => {
					if (res.data.code == 1) {
						this.fileUrl = res.data.data;
						this.fileTitle = res.data.data.originname;
					} else {
						this.$message.error(res.data.msg);
					}
				});
			}
		},
		delFile4() {
			this.file4 = '';
			this.$refs.filElem4.value = '';
			this.fileUrl = '';
		},
		handlePreview(e) {
			this.file = e.data.url;
		},
		handleRemove(e) {
			this.file = '';
		},
		handlePreview1(e) {
			this.give_img = e.data.url;
		},
		handleRemove1(e) {
			this.give_img = '';
		},
		handlePreview2(e) {
			this.success_img = e.data.url;
		},
		handleRemove2(e) {
			this.success_img = '';
		},
		// 关闭弹窗
		closeActivity() {
			this.reset();
			this.activityVisible = false;
			this.activityVisible1 = false;
			this.activityVisible2 = false;
			this.activityVisible3 = false;
			this.activityVisible4 = false;
			this.activityVisible5 = false;
			this.activityVisible6 = false;
			this.activityVisible8 = false;
		},
		handleClose(done) {
			this.reset();
			done();
		},
		handleClose2(done) {
			done();
		},
		// 分页
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			if (this.meunLeftIndex == 1) {
				this.myActivitList();
			} else if (this.meunLeftIndex == 2) {
				this.getduplicate(1);
			} else if (this.meunLeftIndex == 3) {
				this.getduplicate(3);
			} else if (this.meunLeftIndex == 4) {
				this.getduplicate(4);
			}
		},
		// 选中
		handleSelectionChange(val) {
			let arr = [];
			for (let i in val) {
				arr.push(val[i].id);
			}
			this.ids = arr;
			console.log(this.ids);
		},
		// 数据重置
		reset() {
			this.title = '';
			this.start_time = '';
			this.end_time = '';
			this.explain = '';
			this.main_department_id = '';
			this.ectype_department_id = '';
			this.audit = [];
			this.ectype = [];
			this.start_time1 = '';
			this.end_time1 = '';
			this.day = '';
			this.type = 1;
			this.explain = '';
			this.site = '';
			this.describe = '';
			this.file = '';
			this.fileList = [];
			this.plan = '';
			this.give_img = '';
			this.success_img = '';
			this.media_img = '';
			this.attendance_img = '';
			this.activity_img = '';
			this.summarize = '';
			this.fileList1 = [];
			this.fileList2 = [];
			this.file1 = '';
			this.file2 = '';
			this.file3 = '';
			if (this.activityVisible3) {
				this.$refs.filElem1.value = '';
				this.$refs.filElem2.value = '';
				this.$refs.filElem.value = '';
			}
			this.assettype = 2;
			this.number = '';
			this.property_warehouse_id = '';
			this.purpose = '';
			this.plantime = '';
			this.fileTitle = '';
			this.file4 = '';
			this.fileUrl = '';
			this.level = '';
			this.consume_id = [];
			this.origin_units = '';
			this.origin_time = '';
			this.describe = '';
			this.opinion = '';
			if (this.activityVisible5) {
				this.$refs.filElem4.value = '';
			}
		},
		
		twacherTap(index) {
			this.MeunIndex = index;
		},
		meunLeftTap(index) {
			this.meunLeftIndex = index;
			this.page = 1;
			if (index == 1) {
				this.myActivitList();
			} else if (index == 2) {
				this.getduplicate(1);
			} else if (index == 3) {
				this.getduplicate(3);
			} else if (index == 4) {
				this.getduplicate(4);
			}
		},
		// 获取我的请列表
		myActivitList() {
			let data = {
				page: this.page,
				limit: this.limit,
				table_type:1
			};
			this.$api.rule.getMyFlow(data).then(res => {
				if (res.data.code == 1) {
					let data = res.data.data.rows;
					for (let i in data) {
						data[i].createtime = this.$common.timeToDate(data[i].createtime);
					}
					this.Mylist = data;
					this.total = res.data.data.total;
				}
			});
		},
		// 删除活动申请
		delActivit() {
			let _this = this;
			if (this.ids.length) {
				this.$confirm('确定要删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				}).then(() => {
					this.$api.rule
						.delActivit({
							id: this.ids
						})
						.then(res => {
							if (res.data.code == 1) {
								_this.$message.success('删除成功');
								_this.myActivitList();
							} else {
								_this.$message.error(res.data.msg);
							}
						});
				});
			} else {
				this.$message.error('请选择要删除的数据');
			}
		},
		getduplicate(type) {
			let data = {
				page: this.page, // 	1 	string 	是 	分页
				limit: this.limit,
				type: type,
				table_type:2
			};
			this.$api.rule.getduplicate(data).then(res => {
				if (res.data.code == 1) {
					let data = res.data.data.rows;
					for (let i in data) {
						data[i].createtime = this.$common.timeToDate(data[i].createtime);
					}
					this.total = res.data.data.total;
					this.copyToList = data;
				}
			});
		},
		
		// 活动详情
		activitDetails(id) {
			this.$api.rule.activitDetails({ id: id }).then(res => {
				if (res.data.code == 1) {
					this.info = res.data.data;
				}
			});
		},
		
	}
};
</script>

<style lang="scss">
.el_option {
	display: none !important;
}
#activitylist {
	.head {
		background-color: #ffffff;
		padding: 10px 30px;
		margin-top: 15px;
		.span {
			margin-right: 10px;
			background-color: #f9f9f9;
			color: #8d9da6;
			padding: 3px 10px;
		}
	}
	.nav-tab-bar-box {
		background-color: #ffffff;
		padding: 0.52vw;
		margin-top: 1.04vw;
		.nav-tab-bar {
			.nav-tab-item {
				color: #6e7a87;
				padding: 0 0.78125vw;
				border-right: 1px solid #f3f3f3;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			&div:last-child {
				border-right: none;
			}
			.active {
				font-size: 0.833vw;
				color: #63acff;
				position: relative;
				font-weight: bold;
			}
			.active::after {
				position: absolute;
				content: '';
				height: 2px;
				left: 0.78125vw;
				right: 0.78125vw;
				background-color: #63acff;
				bottom: -0.53125vw;
			}
		}
	}
	.content {
		background-color: #ffffff;
		height: 85vh;
		margin-top: 20px;
		.content-left {
			width: 20%;
			border-right: 1px dashed #eeeeee;
			height: 79vh;
			padding: 30px 20px 30px 30px;
			.meun-item {
				cursor: pointer;
				padding: 10px 20px;
				color: #96a2aa;
				.iconfont {
					margin-right: 10px;
				}
			}
			.meun-active {
				background-color: #ddeeff;
				color: #3f9ffe;
			}
		}
		.content-right {
			width: 80%;
			height: 74vh;
			padding: 15px 20px;
			.title {
				padding: 0 0 15px 0;
				.btn {
					background-color: #f5f5f5;
					padding: 5px;
				}
				.btn:hover {
					color: #4998ff;
					background: #e4f1ff;
					cursor: pointer;
				}
			}
			.el-col {
				margin-bottom: 20px;
			}
			.content-item {
				border: 2px solid #f2f3f4;
				.box1 {
					padding: 35px 20px;
					i {
						color: #1890ff;
						margin-right: 5px;
						font-size: 20px;
					}
					span {
						font-weight: bold;
					}
				}
				.btn {
					background-color: #fafafa;
					padding: 10px 0;
					color: #b5bdc2;
					cursor: pointer;
				}
			}
		}
	}

	.el-table th > .cell,
	.el-table .cell {
		overflow: hidden; /*超出部分隐藏*/
		white-space: nowrap; /*不换行*/
		text-overflow: ellipsis; /*超出部分文字以...显示*/
	}
	.el-table thead {
		background: #fafafa !important;
	}
	.el-table th {
		padding: 15px 5px !important;
		background: #fafafa !important;
	}
	.el-table::before {
		left: 0;
		bottom: 0;
		width: 100%;
		height: 0;
	}

	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		height: 600px;
		overflow-y: auto;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					width: 20%;
					text-align: right;
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.input-lebal1 {
					width: 5vw;
					text-align: right;
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
				.upload-file {
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					opacity: 0;
					cursor: pointer;
				}
				.file_box {
					padding: 5px;
					.el-icon-circle-check {
						display: block;
					}
					.el-icon-circle-close {
						display: none;
					}
				}
				.file_box:hover {
					color: #007aff;
					background-color: #f6f8fb;
					.el-icon-circle-check {
						display: none;
					}
					.el-icon-circle-close {
						display: block;
					}
				}
				.el-upload__tip {
					color: #ccc;
				}

				.table-head {
					height: 50px;
					min-height: 21.33333333px;
					background-color: #f6f8fb;
					border-bottom: 1px solid #f0f2f5;
					.class_name {
						font-weight: 700;
						height: 100%;
						border-right: 1px solid #f0f2f5;
						width: 4.85416667vw;
						min-width: 39.46666667px;
						color: #68b0fd;
						font-size: 0.729vw;
					}
					.week_name {
						height: 100%;
						border-right: 1px solid #f0f2f5;
						flex: 1;
						min-width: 64px;
						border-right: 1px solid #f0f2f5;
						color: #8b8b8d;
						font-size: 0.7291666666666666vw;
					}
				}
				.table-item {
					height: 50px;
					min-height: 26.66666667px;
					border-bottom: 1px solid #f0f2f5;
					.time_name {
						height: 100%;
						background-color: #f6f8fb;
						width: 4.85416667vw;
						min-width: 39.46666667px;
						border-right: 1px solid #f0f2f5;
						color: #8b8b8d;
						font-size: 0.7291666666666666vw;
					}
					.cell {
						position: relative;
						overflow-x: hidden;
						height: 100%;
						border-right: 1px solid #f0f2f5;
						width: 6.25vw;
						flex: 1;
						min-width: 64px;
						cursor: pointer;
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						-webkit-box-pack: center;
						-ms-flex-pack: center;
						justify-content: center;
						-webkit-box-align: center;
						-ms-flex-align: center;
						align-items: center;
						-webkit-box-orient: vertical;
						-webkit-box-direction: normal;
						-ms-flex-direction: column;
						flex-direction: column;
						transition: all 0.2s ease 0s;
						-moz-transition: all 0.2s ease 0s;
						-webkit-transition: all 0.2s ease 0s;
						-o-transition: all 0.2s ease 0s;
						-ms-transition: all 0.2s ease 0s;
						p {
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							width: 4.94791667vw;
							min-width: 50.66666667px;
							margin: 0;
							padding: 0;
							border: 0;
							text-align: center;
							// font-size: 0.7291666666666666vw;
						}
						.cell_subject {
							color: #6f6f6f;
							margin-bottom: 0.26041667vw;
						}
						.cell_teacher {
							color: #a9b8c0;
						}
					}
					.active {
						background-color: #1890ff;
						.cell_teacher {
							color: #ffffff;
						}
						.cell_subject {
							color: #ffffff;
						}
					}
				}
				.teacher-list {
					display: flex;
					flex-wrap: wrap;
					width: 72%;
					.teacher-item {
						width: 100px;
						height: 30px;
						line-height: 30px;
						border-radius: 5px;
						text-align: center;
						margin-right: 10px;
						background-color: #eeeeee;
						margin-bottom: 10px;
						cursor: pointer;
					}
					.active {
						background-color: #007aff;
						color: #ffffff;
					}
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
	#audit {
		.el-dialog__body {
			padding: 0 20px 35px 20px;
			height: 300px;
		}
	}
}
.selectShow {
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0;
		.user-list-box {
			.user-list-head {
				padding: 10px 20px;
				border-bottom: 1px solid #eeeeee;
				.user-type-list {
					.user-type-item {
						color: #8d9da6;
						cursor: pointer;
					}
					.active {
						color: #007aff;
						position: relative;
					}
					.active::after {
						position: absolute;
						width: 25px;
						height: 2px;
						background-color: #007aff;
						content: '';
						bottom: -5px;
						left: 0;
					}
					span {
						color: #cccccc;
						margin: 0 8px;
					}
				}
			}
			.user-list-content {
				height: 40vh;
				.left {
					height: 98%;
					overflow-y: auto;
					border-right: 1px solid #eeeeee;
					padding-top: 5px;
					.left-item {
						padding: 0 20px;
						height: 40px;
						line-height: 40px;
						color: #a4a4a4;
						cursor: pointer;
					}
					.active {
						background-color: #e4f1ff;
						color: #2d8cf0;
						position: relative;
					}
					.active:after {
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						width: 2px;
						content: '';
						background-color: #2d8cf0;
					}
				}
				.right {
					height: 37vh;
					padding: 10px 25px;
					overflow-y: auto;
					.list {
						display: flex;
						flex-wrap: wrap;
						.item {
							cursor: pointer;
							padding: 5px 15px;
							border-radius: 3px;
							background-color: #f3f3f3;
							margin-right: 10px;
							margin-bottom: 10px;
						}
						.active {
							background-color: #e4f1ff;
							color: #2d8cf0;
						}
					}
				}
			}
		}
	}
	.dialog-title {
		padding: 10px 0 5px 0;
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
.el-step__description{
	padding-right: 0!important;
}
</style>
